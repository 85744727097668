import { BASE_URL, getApiHeaders } from "./settings";

const URL = 'Account'

const Api = {
    login: async function (params) {
        try {
            const response = await fetch(`${BASE_URL}/${URL}/logon`, {
                headers: getApiHeaders(), method: "POST", body: JSON.stringify(params)
            });
            if (response.status !== 200) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}

export default Api