export const BASE_URL = process.env.REACT_APP_BASE_URL

export const headers = new Headers({ 'Content-Type': 'application/json' })

export const memoizeResolver = (...args) => args.map(JSON.stringify).join('_')

export const SearchParams = (params = {}) => {
  const urlSearchParams = new URLSearchParams()
  for (const key in params)
    urlSearchParams.set(key, params[key])
  return urlSearchParams
}

export const getApiHeaders = () => {
  const token = localStorage.getItem('token')
  return new Headers({
    'Content-Type': 'application/json',
    'Authorization': token
  })
}

export const getToken = () => {
  return sessionStorage.getItem('TokenApi');
}