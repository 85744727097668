import { createContext, useState } from "react";

const ActivesContext = createContext({
    panelList: [],
    selectedActive: null,
    initialState: null,
    onSelectActive: () => { },
    onSetPanelList: () => { },
    onSetInitialState: () => { }
})

export function ActivesContextProvider({ children, ...props }) {
    const [selectedActive, setSelectedActive] = useState()
    const [initialState, setinitialState] = useState()
    const [panelList, setPanelList] = useState([])

    // const onSelectActive = (item) => {
    //     setSelectedActive(item)
    // }

    // const onSetInitialState = (data) => {
    //     setinitialState(data)
    // }

    // const onSetPanelList = (data) => {
    //     setPanelList(data)
    // }

    return (
        <ActivesContext.Provider value={{
            selectedActive,
            onSelectActive: setSelectedActive,
            panelList,
            initialState,
            onSetPanelList: setPanelList,
            onSetInitialState: setinitialState
        }} >
            {children}
        </ActivesContext.Provider>
    )
}

export default ActivesContext