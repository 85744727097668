import { useEffect, useState, useCallback } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Api from "../api/PackagesApi"

const signaturesTypes = [
  {
    signature: '019423',
    label: 'ONE Grátis'
  },
  {
    signature: '019425',
    label: 'ONE Básico'
  },
  {
    signature: '019427',
    label: 'ONE Intermediário'
  },
  {
    signature: '019429',
    label: 'ONE Premium'
  },
]

function Packages() {
  const [packagesData, setPackagesData] = useState([])
  const [categories, setCategories] = useState([])
  const { register, handleSubmit, reset } = useForm({})
  const onSubmit = (data) => {
    const items = Object.entries(data).map(([k, v]) => ({ id: +k, ...v }))
    const newProps = items.map(x => ({ ...packagesData.find(y => x.id === y.id), ...x }))
    Api.postPackages(newProps).catch(() => {
      toast.error("Não foi possível salvar suas alterações")
    }).then(() => {
      reset(data)
      toast.success("Alterações salvas com sucesso!")
    })

  }

  function undo() {
    const form = packagesData.reduce((ac, { id, basico, avancado, intermediario }) => ({ ...ac, [id]: { basico, intermediario, avancado } }), {});
    reset(form)
  }

  const getData = useCallback(() => {
    Api.fetchSignatureModules().then(items => {
      setPackagesData(items)
      const categories = items?.reduce((ac, item) => {
        if (!ac.some(x => x.category === item.category)) {
          ac.push({ category: item.category, items: items.filter(x => x.category === item.category) })
        }
        return ac
      }, [])
      setCategories(categories)
      const form = items.reduce((ac, { id, gratis, basico, intermediario, avancado }) => ({ ...ac, [id]: { gratis, basico, intermediario, avancado } }), {})
      reset(form)
    })
  }, [reset])

  useEffect(() => {
    getData()
  }, [])

  return (

    <div className="bg-white p-8">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between">
          <div>
            <h1 className="font-semibold uppercase text-base">Administração</h1>
            <h1 className="text-4xl font-bold text-primary">Conteúdo</h1>
          </div>
          <div className="flex space-x-3 justify-between">
            <button type="submit" className="h-12 text-secondary btn btn-primary">
              Salvar Alterações
            </button>
            <button onClick={(e) => {
              e.preventDefault()
              undo()
            }} className="h-12 text-primary btn btn-secondary">
              Desfazer
            </button>
          </div>
        </div>

        <div className="relative overflow-x-auto mt-5">
          <table className="w-full text-sm text-left">
            <thead className="bg-gray-200 text-primary border-b-black border">
              <tr>
                <th scope="col" className="px-6 py-3"></th>
                {signaturesTypes.map((item) => {
                  return (
                    <th key={`${item.signature}-row`} scope="col" className="text-xl px-6 py-3">
                      {item.label}
                    </th>
                  )
                })}
              </tr>
              {categories.map(({ category, items }, index) => (<>
                <tr key={category} className="bg-white border-b dark:border-gray-400">
                  <th scope="row" className="font-bold text-lg uppercase px-6 py-3">
                    {category}
                  </th>
                </tr>
                {items.map(item => (
                  <tr key={item.id} className="bg-white border-b dark:border-gray-400">
                    <th
                      key={item.label}
                      scope="row"
                      className="px-6 py-2 font-medium text-base text-gray-900 whitespace-nowrap"
                    >
                      {item.label}
                    </th>
                    <td className="px-6 py-2">
                      <input
                        {...register(`${item.id}.gratis`)}
                        type="checkbox"
                        className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded align-middle"
                      />
                    </td>
                    <td className="px-6 py-2">
                      <input
                        {...register(`${item.id}.basico`)}
                        type="checkbox"
                        className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded align-middle"
                      />
                    </td>
                    <td className="px-6 py-2">
                      <input
                        {...register(`${item.id}.intermediario`)}
                        type="checkbox"
                        className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded align-middle"
                      />
                    </td>
                    <td className="px-6 py-2">
                      <input
                        {...register(`${item.id}.avancado`)}
                        type="checkbox"
                        className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded align-middle"
                      />
                    </td>
                  </tr>
                ))}
              </>)
              )}
            </thead>
          </table>
        </div>
        <div className="flex space-x-3 justify-end mt-5">
          <button type="submit" className="h-12 text-secondary btn btn-primary">
            Salvar Alterações
          </button>
          <button onClick={(e) => {
            e.preventDefault()
            undo()
          }} className="h-12 text-primary btn btn-secondary">
            Desfazer
          </button>
        </div>
      </form>
    </div>
  )
}

export default Packages
