// import IcoPerfil from "assets/icons/ico-menu-perfil.svg";
import { MdList, MdOutlineGroup } from "react-icons/md";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { MdOutlineDashboard } from "react-icons/md";

const menuItems = [
  {
    name: "Módulos",
    url: "/modulos",
    type: "regular",
    ico: <MdOutlineDashboardCustomize />,
    subitems: null,
  },
  {
    name: "Conteúdo",
    url: "/conteudo",
    type: "regular",
    ico: <MdOutlineDashboard />,
    subitems: null,
  },
  {
    name: "Ativos",
    url: "/ativos",
    type: "regular",
    ico: <MdList />,
    subitems: null,
  },
  {
    name: "Perfil",
    url: null,
    type: "inverted",
    ico: <MdOutlineGroup />,
    subitems: [{ name: "Sair", url: "/login", type: "subitem" }],
  },
];

function useMenu() {
  return {
    menuItems,
  };
}

export default useMenu;
