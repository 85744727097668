import { Link } from "react-router-dom";
import { MdList, MdOutlineDashboardCustomize } from "react-icons/md";
import { MdOutlineDashboard } from "react-icons/md";

const items = [
  {
    title: "Módulos",
    path: "/modulos",
    icon: <MdOutlineDashboardCustomize className="text-7xl" />
  },
  {
    title: "Conteúdo",
    path: "/conteudo",
    icon: <MdOutlineDashboard className="text-7xl" />
  },
  {
    title: "Listas",
    path: "/ativos",
    icon: <MdList className="text-7xl" />
  },
]

function Home() {

  const token = JSON.stringify(localStorage.getItem('token')).slice(7)
  console.log(token);

  return (
    <div className="flex items-center justify-evenly py-40">
      {
        items.map((item, i) => {
          return (
            <Link
              key={item.title + i}
              to={item.path}
              className="flex flex-col justify-center items-center text-secondary bg-primary w-44 h-52 rounded-md p-5 hover:scale-105 transition"
            >
              {item.icon}
              <h1 className="mt-5 uppercase text-secondary font-bold">{item.title}</h1>
            </Link>
          )
        })
      }
    </div>
  );
}

export default Home;
