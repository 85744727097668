import Swal from "sweetalert2";

export const warningAlert = Swal.mixin({
  title: "Tem certeza que deseja fazer isso?",
  text: "Essa ação é irreversível!",
  icon: "warning",
  showCancelButton: true,
  reverseButtons: true,
  confirmButtonColor: "#472f92",
  cancelButtonColor: "#7a7a7a",
  confirmButtonText: "Confirmar!",
  cancelButtonText: "Cancelar",
});
