import { memoize } from "lodash";
import { BASE_URL, memoizeResolver, getApiHeaders } from "./settings";

const URL = "Admin";

const Api = {
    fetchSignatureModules: async function (data = {}) {
        try {
            const response = await fetch(`${BASE_URL}/${URL}/packages`, {
                headers: getApiHeaders()
            });
            if (response.status !== 200) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    postPackages: memoize(async function (params) {
        try {
            const response = await fetch(`${BASE_URL}/${URL}/packages`, {
                headers: getApiHeaders(), method: "PUT", body: JSON.stringify(params)
            });
            if (response.status !== 200) {
                console.error(response);
                throw new Error(`Error: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error(error);
            return null;
        }
    }, memoizeResolver),
};

export default Api;